@import url('https://fonts.googleapis.com/css2?family=Russo+One&display=swap');
body{
  /* background-color: #272537 !important; */
  background: linear-gradient(0deg, rgba(39,37,55,0.835171568627451) 0%, rgba(39,37,55,1) 100%) !important;
  
  /* background-image: linear-gradient(25deg, #4559dc, #6186c7, #66b2b0, #57de96);
  background-repeat: no-repeat;
  background-size: cover; */
  min-height: 100vh;
  color: #3F3844
}
header{
  font-weight: bold;
  /* font-family: 'Russo One', sans-serif; */
  font-size: 2.5rem;
  color: #F7F8F7;
  text-align: center;
  padding: 1rem 0;
  text-shadow: .05em .05em 0 hsl(200 50% 30%);;
}
.btn-primary{
  background-color: rgba(19, 38, 102, 0.702) !important;
  border: none !important;
  /* background-image: linear-gradient(25deg, #4559dc, #6186c7, #66b2b0, #57de96); */
}
.btn-primary:hover{
  background-color: rgba(19, 38, 102, 0.8) !important;

}
.btn-modal{
  background-color: #F7F8F7 !important;
  color:rgba(19, 38, 102, 0.7) !important;
}
.btn-secondary{
  background:transparent !important;
  color: black !important;
  border: 3px solid rgba(19, 38, 102, 0.7) !important;
}
.form-control:focus{
  border: 1px solid #ced4da !important;
  box-shadow: none !important;
}
.App {
  /* text-align: center; */
  color: #3F3844;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}


#main-form{
  background-color: #F7F8F7;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
#note-div{
  max-height: 15vh;
  border-color: rgba(19, 38, 102, 0.9) !important;
  overflow-y: hidden;
  word-wrap: break-word;
  padding-right: 5rem;
}
textarea{
  resize: none;
}
.roundRight{
  border-radius: 0 0.375rem 0.375rem 0 !important;
}
.roundLeft{
  border-radius: 0.375rem 0 0 0.375rem !important;
}
#main-container{
  align-self: center;
  display: flex;
  justify-content: space-between;
}
#lamp-container{
  height: 60vh;
  display: flex;
  justify-content: center;

}
#lamp{
  display: flex;
  flex-direction: column;
  align-items: center;
}
#lamp-pole{
  height: 5rem;
  width: 1rem;
  background-color: #41403e;
}
#lamp-middle{
  height:1rem;
  width: 2rem;
  border-radius: 0.6rem 0.6rem 0 0;
  background-color: #41403e;
}
#lamp-bottom{
  height: 3rem;
  width: 10rem;
  border-radius: 6rem 6rem 0 0;
  background-color: #41403e;
}
#glow{
  height: 100%;
  width: 45rem;
  /* background: linear-gradient(0deg, rgba(246,255,35,0) 0%, rgba(240,255,25,0.85) 100%); */
  clip-path: polygon(40% 0, 60% 0, 100% 100%, 0% 100%);
  transition: ease
}
#btn-container{
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
